<template>
  <div>
    <b-row class="px-2">
      <b-col class="mb-2" cols="4">
        <h1 class="mb-0">Redesconto detalhado</h1>
      </b-col>

      <b-col class="d-flex justify-content-end align-items-end mb-2" cols="8">
        <b-button
          variant="outline-secondary"
          class="d-flex justify-content-between align-items-center bg-white"
          @click="voltarButton"
        >
          <feather-icon icon="ArrowLeftIcon" class="mr-1" />
          <span class="text-dark font-weight-bolder"> Voltar </span>
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col xl="3" lg="3" md="6" class="mb-0">
        <b-card class="control-border-color mb-2">
          <div class="fz-16 d-flex justify-content-between align-items-center">
            <span> ID </span>
            <h5 class="mb-0 ml-2 font-weight-bolder truncate-text">{{ redesconto.id }}</h5>
          </div>
          <div class="fz-16 d-flex justify-content-between align-items-center">
            <span> Cedente </span>
            <h5
              class="mb-0 ml-2 font-weight-bolder truncate-text"
              v-b-tooltip.hover
              :title="redesconto.cedente_razao_social"
            >
              {{ redesconto.cedente_razao_social }}
            </h5>
          </div>
          <div class="fz-16 d-flex justify-content-between align-items-center">
            <span> Remessa </span>
            <a
              :href="`/admin/concessoes/${redesconto.remessa_id}`"
              class="mb-0 ml-2 font-weight-bolder truncate-text underline"
              v-b-tooltip.hover
              title="Visualizar remessa"
              v-if="$route.name === 'redescontos.show'"
            >
              {{ redesconto.remessa_numero }}
            </a>

            <a
              :href="`/parceiro/remessas/${redesconto.remessa_id}`"
              class="mb-0 ml-2 font-weight-bolder truncate-text underline"
              v-b-tooltip.hover
              title="Visualizar remessa"
              v-else
            >
              {{ redesconto.remessa_numero }}
            </a>
          </div>
          <div class="fz-16 d-flex justify-content-between align-items-center">
            <span> Data de envio </span>
            <h5 class="mb-0 font-weight-bolder">{{ redesconto.created_at | dateFormat }}</h5>
          </div>
          <div class="fz-16 d-flex justify-content-between align-items-center">
            <span> Status </span>
            <div
              v-if="redesconto.status != 'rejeitado'"
              v-html="statusRedescontoFormatter(redesconto.status)"
            ></div>
            <div v-else class="d-flex justify-content-end align-items-center">
              <span v-html="statusRedescontoFormatter(redesconto.status)" class="mr-1"></span>
              <feather-icon
                icon="AlertCircleIcon"
                class="text-danger"
                size="22"
                v-b-tooltip.hover
                :title="redesconto.motivo_rejeicao"
              />
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col xl="3" lg="3" md="6" class="d-flex flex-column justify-content-between">
        <b-card class="control-border-color mb-1">
          <div class="fz-16 d-flex justify-content-between align-items-center py-50">
            <span> Total de baixa </span>
            <h3 class="mb-0 font-weight-bolder">{{ redesconto.total_baixa | moneyFormat }}</h3>
          </div>
        </b-card>
      </b-col>
      <b-col xl="3" lg="3" md="6" class="d-flex flex-column justify-content-between">
        <b-card class="control-border-color mb-1">
          <div class="fz-16 d-flex justify-content-between align-items-center py-50">
            <span> Total operado </span>
            <h3 class="mb-0 font-weight-bolder">{{ redesconto.total_operado | moneyFormat }}</h3>
          </div>
        </b-card>
      </b-col>
      <b-col xl="3" lg="3" md="6" class="d-flex flex-column justify-content-between">
        <b-card class="control-border-color mb-1">
          <div class="fz-16 d-flex justify-content-between align-items-center py-50">
            <span> Quantidade de títulos </span>
            <h3 class="mb-0 font-weight-bolder">{{ redesconto.quantidade_titulos || '0' }}</h3>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <datatable
      ref="concessoesDatatable"
      :serviceUrl="`/recebiveis/cashu/redesconto/${this.$route.params.id}/titulos`"
      :customFields="customFields"
      datatableTitleProps="Títulos"
    >
    </datatable>
  </div>
</template>

<script>
import { BCol, BRow, BCard, BButton, VBTooltip, BModal, FormSelectPlugin } from 'bootstrap-vue'

import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'
import Datatable from '@/views/common/crud/components/Datatable'
import ExtractIndex from '@/views/admin/extract/pages/Index.vue'

import {
  moneyFormatDatatable,
  brazilianFormattedDate,
  statusRedescontoFormatter
} from '@core/comp-functions/data_visualization/datatable'

import { truncateWithEllipsis } from '@core/comp-functions/data_visualization/string'

export default {
  name: 'ShowConcession',
  components: {
    BCol,
    BRow,
    BCard,
    BModal,
    BButton,
    vSelect,
    flatPickr,
    Datatable,
    VBTooltip,
    ExtractIndex
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  filters: {
    moneyFormat: moneyFormatDatatable,
    dateFormat: brazilianFormattedDate,
    truncateWithEllipsis,
  },
  data() {
    return {
      redesconto: {},
      showTitle: true,
      statuses: [
        { value: 'pendente', label: 'Pendente' },
        { value: 'importado', label: 'Importado' },
        { value: 'rejeitado', label: 'Rejeitado' },
      ],
      config: {
        mode: 'range',
        locale: Portuguese,
        dateFormat: 'd/m/Y',
      },
      customFields: [
        {
          label: 'Número',
          key: 'numero',
          sortable: true,
        },
        {
          label: 'Sacado',
          key: 'sacado',
          sortable: true,
          tdClass: 'truncate-text',
        },
        {
          label: 'Emissão',
          key: 'data_emissao',
          sortable: true,
          formatter: brazilianFormattedDate,
        },
        {
          label: 'Vencimento',
          key: 'data_vencimento',
          sortable: true,
          formatter: brazilianFormattedDate,
        },
        {
          label: 'Valor de face',
          key: 'valor_face',
          sortable: true,
          formatter: moneyFormatDatatable,
        },
        {
          label: 'Valor operado',
          key: 'valor_operado',
          sortable: true,
          formatter: moneyFormatDatatable,
        },
        {
          label: 'Valor baixa',
          key: 'valor_baixa',
          sortable: true,
          formatter: moneyFormatDatatable,
        }
      ],
    }
  },
  async mounted() {
    await this.getRedesconto()
  },
  methods: {
    truncateWithEllipsis,
    statusRedescontoFormatter,
    async getRedesconto() {
      const data = await this.$store.dispatch('admin/getRedesconto', this.$route.params.id)
      this.redesconto = data
    },
    voltarButton() {
      if (this.$router.currentRoute.name === 'partner.redescontos.show') {
        this.$router.push({ name: 'partner.redescontos.index' })
      } else {
        this.$router.push({ name: 'redescontos.index' })
      }
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.fz-16 {
  font-size: 16px;
  line-height: 24px;
}

.control-border-color {
  .card-body {
    border: 1px solid #dae1e8;
    border-radius: 6px;
  }
}

.control-border-color .card-body {
  padding: 14px 16px 14px 16px !important;
}

.mw-259 {
  min-width: 259px;
}

.assignor-selector {
  min-width: 235px;
}

</style>
